var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":_vm.pageTitle}}),_c('div',{staticClass:"main-content"},[_c('a-tabs',{attrs:{"default-active-key":_vm.tabKey,"animated":false},on:{"change":_vm.callback}},_vm._l((_vm.tabs),function(item){return _c('a-tab-pane',{key:item.key,attrs:{"tab":item.name}},[_c('my-table',{attrs:{"columns":_vm.columns,"data-source":item.data,"pagination":false,"loading":_vm.loading,"scroll":{ x: 1000 }},scopedSlots:_vm._u([{key:"lstatus",fn:function(record){return [_c('a-badge',{attrs:{"status":_vm.statuText[record.lstatus].statu,"text":_vm.statuText[record.lstatus].text}})]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"}},[_vm._v(" 修改名称 ")]),(record.lstatus)?_c('router-link',{attrs:{"to":{
                path: '/accountStarConfig',
                query: {
                  id: record.id,
                  type: record.userType,
                  name: record.name
                }
              }}},[_vm._v(" 角色星级配置 ")]):_vm._e()]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }