<template>
  <!-- 系统账号类型 -->
  <div class="container">
    <a-page-header :title="pageTitle" />
    <div class="main-content">
      <a-tabs :default-active-key="tabKey" :animated="false" @change="callback">
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name">
          <my-table
            :columns="columns"
            :data-source="item.data"
            :pagination="false"
            :loading="loading"
            :scroll="{ x: 1000 }"
          >
            <template slot="lstatus" slot-scope="record">
              <a-badge
                :status="statuText[record.lstatus].statu"
                :text="statuText[record.lstatus].text"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link">
                修改名称
              </a-button>
              <router-link
                :to="{
                  path: '/accountStarConfig',
                  query: {
                    id: record.id,
                    type: record.userType,
                    name: record.name
                  }
                }"
                v-if="record.lstatus"
              >
                角色星级配置
              </router-link>
            </template>
          </my-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import myTable from '@/components/my-table'
export default {
  data() {
    return {
      tabs: [
        {
          key: 0,
          type: 2,
          name: '前台业务账号',
          data: []
        },
        {
          key: 1,
          type: 1,
          name: '中台业务账号',
          data: []
        },
        {
          key: 2,
          type: 3,
          name: '中台管理员',
          data: []
        }
      ],
      tabKey: 0,
      columns: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '账号类型',
          dataIndex: 'typeremark',
          width: 200
        },
        {
          title: '角色名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '角色星级管理',
          scopedSlots: {
            customRender: 'lstatus'
          }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 250,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      statuText: [
        {
          text: '不支持',
          statu: 'default'
        },
        {
          text: '支持',
          statu: 'success'
        }
      ]
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  components: {
    myTable
  },
  created() {
    this.getAccountType(0)
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabKey = key
      this.getAccountType(key)
    },
    getAccountType(key) {
      // 获取系统账号类型
      const data = {
        type: this.tabs[key].type
      }
      this.$axios.getAccountType(data).then((res) => {
        this.tabs[key].data = res.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style></style>
