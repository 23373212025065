<template>
  <!-- 账号星级配置 -->
  <div class="container">
    <a-page-header
      :title="pageTitle"
      :subTitle="accountName"
      @back="() => $router.go(-1)"
    />
    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span class="title-1">{{ accountName }}</span>
          </div>
        </div>
      </div>
      <div class="body">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-key="(record) => record.id"
          :loading="loading"
          :scroll="{ x: 1000 }"
        >
          <template slot="level" slot-scope="record">
            <a-rate v-model="record.level" disabled />
          </template>
          <template slot="name" slot-scope="record">
            <a-input v-if="record.editable" v-model="newName" />
            <template v-else> {{ record.name }} </template>
          </template>
          <template slot="action" slot-scope="text, record, index">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a-button
                  type="link"
                  @click="() => onAdd(index)"
                  v-if="actionType === 'add'"
                >
                  添加
                </a-button>
                <a-button type="link" @click="() => onSave(index)" v-else>
                  保存
                </a-button>
                <a-popconfirm
                  title="确定取消?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="() => onCancel(index)"
                >
                  <a-button type="link">取消</a-button>
                </a-popconfirm>
              </span>
              <a-button
                type="link"
                v-else
                :disabled="actionType !== ''"
                @click="() => onEdit(index)"
              >
                编辑
              </a-button>
              <a-button
                type="link"
                :disabled="actionType !== ''"
                @click="onDel(record.id)"
              >
                删除
              </a-button>
            </div>
          </template>
        </a-table>
        <a-button
          class="plus-btn"
          type="dashed"
          icon="plus"
          :disabled="actionType !== '' || dataSource.length === 5"
          @click="onPlus"
        >
          增加星级
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let accountId = ''
let userType = ''
export default {
  data() {
    return {
      title: '',
      accountName: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '星级',
          scopedSlots: {
            customRender: 'level'
          }
        },
        {
          title: '星级描述',
          width: 200,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 250,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      newName: '', // 修改描述
      actionType: '' // 操作类型：添加、编辑
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  created() {
    const query = this.$route.query
    this.accountName = query.name
    accountId = query.id
    userType = query.type
    this.getAccountStarConfig()
  },
  methods: {
    getAccountStarConfig() {
      // 获取对应角色的星级配置
      const data = {
        id: accountId,
        userType: userType
      }
      this.$axios.getAccountStarConfig(data).then((res) => {
        this.dataSource = res.data.data
        this.loading = false
      })
    },
    onPlus() {
      // 添加星级
      this.actionType = 'add'
      let level = 0
      const index = this.dataSource.length - 1
      if (index > -1) {
        level = this.dataSource[index].level
      }
      this.dataSource.push({
        id: 0,
        level: level + 1,
        name: '',
        editable: true
      })
    },
    onEdit(i) {
      // 编辑
      this.actionType = 'edit'
      const target = this.dataSource[i]
      this.newName = target.name
      target.editable = true
      this.dataSource.splice(i, 1, target)
    },
    onSave(i) {
      // 保存
      const target = this.dataSource[i]
      target.name = this.newName
      delete target.editable
      this.dataSource.splice(i, 1, target)
      this.actionType = ''
      const data = {
        id: target.id,
        name: target.name
      }
      this.$axios.editAccountStarConfig(data).then(() => {
        this.$message.success('保存成功')
      })
    },
    onAdd() {
      // 添加
      const i = this.dataSource.length - 1
      const target = this.dataSource[i]
      target.name = this.newName
      delete target.editable
      this.dataSource.splice(i, 1, target)
      this.actionType = ''
      const data = {
        level: target.level,
        name: target.name,
        userType: userType
      }
      this.$axios.addAccountStarConfig(data).then(() => {
        this.$message.success('添加成功')
        this.getAccountStarConfig()
        this.newName = ''
      })
    },
    onCancel(i) {
      // 取消
      const target = this.dataSource[i]
      delete target.editable
      this.dataSource.splice(i, 1, target)
      this.newName = ''
      if (this.actionType === 'add') {
        this.dataSource.splice(this.dataSource.length - 1, 1)
      }
      this.actionType = ''
    },
    onDel(id) {
      // 删除
      this.$confirm({
        content: '确定删除?',
        onOk: () => {
          const data = {
            id: id
          }
          this.$axios.delAccountStarConfig(data).then(() => {
            this.$message.success('删除成功')
            this.getAccountStarConfig()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.body {
  padding: 16px 32px;
  background-color: #fff;
}

.plus-btn {
  width: 100%;
  margin-top: 16px;
}
</style>
